import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles';
import {
  Link,
  Box,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GlobalActions from 'stores/global/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningBoard: {
      position: 'fixed',
      right: 0,
      zIndex: 1399,
      alignItems: 'center',
      justifyContent: 'center',
      width: '160px',
      height: '56px',
      backgroundColor: '#0052a3',
      color: 'white',
    }
  })
)

const WarningBoard = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes: any = useStyles();
  const [didMount, setDidMount] = React.useState<any>(false);
  const [env, setEnv] = React.useState<any>(window.Config.NODE_ENV);
  const [routeIsUser, setRouteIsUser] = React.useState<any>(true);
  const match = useRouteMatch('/user');

  const checkRoute = () => {
    if (window.location.pathname.length > 0) {
      setRouteIsUser((match !== null));
    }
  }

  useEffect(() => {
    if (didMount && window.location.pathname.length > 0) {
      checkRoute();
    }
  }, [didMount, window.location.pathname])

  useEffect(() => {
    if (didMount) {
      checkRoute();
    }
  }, [didMount])

  useEffect(() => {
    setDidMount(true);
  }, [])

  return (
    <Box display={env !== 'prod' && !routeIsUser ? 'flex' : 'none'} className={classes.warningBoard}>
      <Box mr={1} display="inline-flex"><ErrorOutlineIcon style={{width: '24px', height: '24px'}} /></Box>
      <Box fontWeight="500">此版本為測試版</Box>
    </Box>
  )
}
export default WarningBoard;