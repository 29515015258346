import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Paper,
  Button,
  Link,
  Tooltip,
  IconButton,
  LinearProgress,
 } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ChangePasswordDialog from './components/ChangePasswordDialog';
import SettingPasswordDialog from './components/SettingPasswordDialog';
import ResetWalletPasswordDialog from './components/ResetWalletPasswordDialog';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Config from 'config/basic/config.json';
import UserActions from 'stores/otc/user/actions';
import FeatureToggle, {featureToggle} from 'components/ACL/FeatureToggle';


const dialogConfig = Config.component.dialog;
const CustomLink = withStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: '14px',
      fontWeight: 500,
    },
  }),
)((props: any) => <Link {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    walletPassword: {
      '& > span': {
        display: 'flex',
      },
    },
    tooltipWidth: {
      maxWidth: 800,
    },
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      },
      fundPwWrapper: {
        justifyContent: 'space-between',
      },
      walletPassword: {
        '& > span': {
          display: 'inline',
        },
      },
    },
  }),
);

const Index = (props: any) => {
  const {
    intl,
    history,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [didMount, setDidMount] = React.useState<any>(false);
  const meta = useSelector((state:any) => state.global.meta);
  const configAclSet: any = useSelector((state: any) => state.global.featureAcl);
  const apiAclSet = meta.data.features;
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const user = sharedData.user;
  const account = useSelector((state:any) => state.otc.user);
  const KYCStatus = account.kycStatus?.data;
  const isKYC = KYCStatus === 'PASS';
  const walletPassword = user.data.isSetFundPassword;


  const getWalletPasswordUI = () => {
    let result: any;

    if ((featureToggle('kyc', configAclSet, apiAclSet) ? isKYC : true) && walletPassword) {
      result = (
        <Box display="flex">
          <Box><CustomLink onClick={() => {showChangePasswordDialog('wallet')}} component="button" color="primary"><FormattedMessage id="action.modify.without.format" /></CustomLink></Box>
          <Box ml={2}><CustomLink onClick={showResetWalletPasswordDialog} component="button" color="primary"><FormattedMessage id="action.forgetpassword" /></CustomLink></Box>
        </Box>
      );
    } else if ((featureToggle('kyc', configAclSet, apiAclSet) ? isKYC : true) && !walletPassword) {
      result = (
        <>
          <Box display="inline-flex">
            <CustomLink onClick={showSettingPasswordDialog} component="button" color="primary"><FormattedMessage id="action.setting.wallet.password" /></CustomLink>
            <Box ml={1} display="inline-flex">
              <Tooltip classes={{ tooltip: classes.tooltipWidth }} placement={isMobileScreen ?"bottom": "right"} title={intl.formatMessage({id: 'tooltip.setting.wallet.password'})} color="primary" enterTouchDelay={200}>
                <ErrorIcon fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
        </>
      );
    } else {
      result = (
        <Box className={classes.walletPassword} color="secondary">
          <FormattedMessage
            id="description.wallet.password.not.yet.kyc"
            values={{ KYCLink: <CustomLink onClick={() => history.push('/account/authentication')} component={isMobileScreen ? "span" : "button"} color="primary">{intl.formatMessage({ id: "action.kyc"})}</CustomLink>}}
          />
        </Box>
      );
    }

    return result;
  }

  const showChangePasswordDialog = (passwordType: string) => {
    const dialogTitle = passwordType === 'login' ? 'dialog.title.change.login.password' : 'dialog.title.change.wallet.password';

    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: dialogTitle}),
      component: <ChangePasswordDialog
                   {...props}
                   passwordType={passwordType}
                   dialogConfig={dialogConfig}
                 />,
      contentHasPadding: false,
    }));
  }

  const showSettingPasswordDialog = () => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.setting.wallet.password'}),
      component: <SettingPasswordDialog
                   {...props}
                   dialogConfig={dialogConfig}
                 />,
      contentHasPadding: false,
    }));
  }

  const showResetWalletPasswordDialog = () => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.reset.wallet.password'}),
      component: <ResetWalletPasswordDialog
                   {...props}
                   dialogConfig={dialogConfig}
                 />,
      contentHasPadding: false,
    }));
  }

  useEffect(()=> {
    setDidMount(true);
    if (featureToggle('kyc', configAclSet, apiAclSet)) {
      dispatch(UserActions.getKycStatusRequest());
    }
  }, [])

  return (
    <Box>
      <Paper className={classes.paperStyle}>
        <Box px={isMobileScreen?2:4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="label.password.settings" /></Typography>
        </Box>
        <Box px={isMobileScreen?2:4} py={2}>
          <Box display="flex" alignItems="center" justifyContent={isMobileScreen ? 'space-between' :'flex-start'}>
            <Box><FormattedMessage id="label.login.password" /></Box>
            <Box ml={2}><CustomLink onClick={() => {showChangePasswordDialog('login')}} component="button" color="primary"><FormattedMessage id="action.modify.without.format" /></CustomLink></Box>
          </Box>
          {didMount && (featureToggle('kyc', configAclSet, apiAclSet) ? account?.kycStatus.loading : false) &&
            <LinearProgress />
          }
          {didMount && (featureToggle('kyc', configAclSet, apiAclSet) ? account?.kycStatus.loaded : true) &&
            <Box mt={3} display="flex" alignItems="center" justifyContent={isMobileScreen ? 'space-between' : 'flex-start'}>
              <Box minWidth='72px'><FormattedMessage id="label.wallet.password" /></Box>
              {
                getWalletPasswordUI()
              }
            </Box>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default injectIntl(Index);
