import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Button,
  Avatar,
  useMediaQuery,
 } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import notTraderIcon from 'pages/otc/assets/images/not_trader.svg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    levelIcon: {
      width: '64px',
      height: '64px',
      border: '1px solid #9b9b9b',
      borderRadius: '100px',
      fontSize: '2.8em',
      color: '#000000',
      fontWeight: 'bold',
    },
  }),
);

const LevelUpDialog = (props: any) => {
  const {
    intl,
    CurrentLevel,
    CurrentLevelDisplayName,
    NextLevelDisplayName,
    NextLevel,
    Deposit,
    TradeableAmount,
    LevelUpRequirement,
    DialogConfig,
    history,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const goToPage = (path: any) => {
    history.push(path);
    dispatch(GlobalActions.toogleDialog({visible: false}));
  };

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}} color="#2e2e2e">
        <Box mb={3} display="inline-flex" alignItems="center">
          {CurrentLevel > -1 ?
            <Avatar
              className={classes.levelIcon}
              style={{backgroundColor: '#ffffff'}}
            >
              {CurrentLevelDisplayName}
            </Avatar>
            :
            <img src={notTraderIcon} />
          }
          <Box mx={3}><ArrowRightAltIcon fontSize="large" /></Box>
          <Avatar
            className={classes.levelIcon}
            style={{backgroundColor: '#ffffff', margin: '0 auto'}}
          >
            {NextLevelDisplayName}
          </Avatar>
        </Box>
        <Box><FormattedMessage id="dialog.level.up.description.1" values={{nextLevelDisplayName: NextLevelDisplayName}} /></Box>
        <Box><FormattedMessage id="dialog.level.up.description.2" values={{deposit: intl.formatNumber(Deposit, {unitDisplay: 'long'}), tradeableAmount: intl.formatNumber(TradeableAmount, {unitDisplay: 'long'})}} /></Box>
        <Box mt={2} mb={1}><FormattedMessage id="dialog.level.up.description.3" values={{nextLevel: NextLevel}} /></Box>
        <Box fontSize="20px">{`${intl.formatNumber(LevelUpRequirement, {unitDisplay: 'long'})} USDT`}</Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent={isMobileScreen?"center":"flex-end"} borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button onClick={() => goToPage('/otc/wallet')} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.level.up" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(LevelUpDialog);
