import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  Paper,
  Avatar,
  LinearProgress,
 } from '@material-ui/core';
import LevelUpConfirmDialog from './components/LevelUpConfirmDialog';
import LevelUpSuccessDialog from './components/LevelUpSuccessDialog';
import LevelUpDialog from './components/LevelUpDialog';
import LevelUpRequirementDialog from './components/LevelUpRequirementDialog';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Config from 'config/basic/config.json';
import UserActions from 'stores/otc/user/actions';
import BalanceActions from 'stores/otc/balance/actions';
import BankCardActions from 'stores/otc/bankCard/actions';
import UniversalActions from 'stores/otc/universal/actions';
import romanNumeralConverter from 'roman-numeral-converter-mmxvi';
import notTraderIcon from 'pages/otc/assets/images/not_trader.svg';


const dialogConfig = Config.component.dialog;
const LevelLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: '#efefef',
    },
    bar: {
      backgroundColor: '#19d28b',
    },
  }),
)(LinearProgress);

const CustomTypography = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 500,
    },
  }),
)(Typography);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    levelIcon: {
      width: '64px',
      height: '64px',
      border: '1px solid #9b9b9b',
      borderRadius: '100px',
      fontSize: '2.8em',
      color: '#000000',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      }
    },
  }),
);

const TraderLevel = (props: any) => {
  const {
    intl,
  } = props;

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const balance = useSelector((state:any) => state.otc.balance);
  const account = useSelector((state:any) => state.otc.user);
  const traderLevel = useSelector((state:any) => state.account.traderLevel);
  const bankAccountList = useSelector((state: any) => state.otc.bankCard.bankAccountList);
  const otc = useSelector((state:any) => state.otc);
  const user = sharedData.user;
  const isTrader = user.data.status === 'ACTIVE';
  const currentLevel = user.data.level;
  const lowestLevel = otc.universal.otcLevels.data[0]?.level;
  const nextLevel = currentLevel < lowestLevel ? lowestLevel : currentLevel + 1;
  const earnestMoney =  Number(balance.otcBalance.data.pledge) + Number(balance.otcBalance.data.balance) + Number(balance.otcBalance.data.frozenDeposits);
  const currentDeposit = earnestMoney;
  const levels = otc.universal.otcLevels.data;
  const levelUpScore = otc.universal.otcLevels.data.map((item: any) => item.totalAmount);
  const tradeableAmount = otc.universal.otcLevels.data.map((item: any) => item.chargeAmount);
  const [didMount, setDidMount] = React.useState<any>(false);


  const showLevelUpDialog = () => {
    const currentDepositGoalAchieved = getLevelUpScore(currentLevel);
    const currentTradeableAmount = otc.universal.otcLevels.data.filter((item: any) => item.level === nextLevel)[0].chargeAmount;

    if (currentDeposit >= getLevelUpScore(currentLevel)) {
      dispatch(GlobalActions.toogleDialog({
        visible: true,
        title: intl.formatMessage({id: 'dialog.title.trader.level.up'}),
        component: <LevelUpConfirmDialog
                     {...props}
                     DialogConfig={dialogConfig}
                     NextLevel={nextLevel}
                     Deposit={currentDepositGoalAchieved}
                     TradeableAmount={currentTradeableAmount}
                   />,
        contentHasPadding: false,
      }));
    } else {
      dispatch(GlobalActions.toogleDialog({
        visible: true,
        title: intl.formatMessage({id: 'dialog.title.trader.level.up'}),
        component: <LevelUpDialog
                     {...props}
                     DialogConfig={dialogConfig}
                     CurrentLevel={currentLevel}
                     CurrentLevelDisplayName={getLevelDisplayName(currentLevel)}
                     NextLevelDisplayName={getLevelDisplayName(nextLevel)}
                     NextLevel={nextLevel}
                     Deposit={otc.universal.otcLevels.data.filter((item: any) => item.level === nextLevel)[0].totalAmount}
                     TradeableAmount={otc.universal.otcLevels.data.filter((item: any) => item.level === nextLevel)[0].chargeAmount}
                     LevelUpRequirement={getLevelUpRequirement(currentDeposit, getLevelUpScore(currentLevel))}
                   />,
        contentHasPadding: false,
      }));
    }
  }

  const showLevelUpRequirementDialog = () => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.level.up.requirement'}),
      component: <LevelUpRequirementDialog
                   {...props}
                   DialogConfig={dialogConfig}
                   Levels={levels}
                   GetLevelDisplayName={getLevelDisplayName}
                 />,
      contentHasPadding: false,
    }));
  }

  const getLevelDisplayName = (level: number) => {
    let result = null;

    if (level > 0) {
      result = romanNumeralConverter.getRomanFromInteger(level);
    } else if (level === 0) {
      result = '0';
    } else {
      result = null;
    }

    return result;
  }

  const getLevelUpScore = (currentLevel: number) => {
    const levelUpScoreMap = levelUpScore;

    return currentLevel < lowestLevel ? levelUpScoreMap[0] : levelUpScoreMap[currentLevel];
  }

  const isHighestLevel = (currentLevel: number) => {
    const levelList = otc.universal.otcLevels.data.map((item: any) => item.level);
    const highestLevel = levelList[levelList.length - 1];
    return currentLevel === highestLevel;
  }

  const getLevelUpRequirement = (currentScore: number, levelUpScore: number) => {
    return levelUpScore - currentScore;
  }

  const getLevelUpProgress = (currentScore: number, levelUpScore: number) => {
    return currentScore < levelUpScore ? currentScore / levelUpScore * 100 : 100;
  }

  useEffect(() => {
    if (didMount && user.loaded && user.updated) {
      dispatch(GlobalActions.toogleDialog({
        visible: true,
        title: '',
        component: <LevelUpSuccessDialog
                     {...props}
                     DialogConfig={dialogConfig}
                     CurrentLevel={nextLevel}
                     CurrentLevelDisplayName={getLevelDisplayName(nextLevel)}
                   />,
        contentHasPadding: false,
      }));
    }
  }, [user.updated])

  useEffect(()=> {
    setDidMount(true);
    dispatch(UniversalActions.getOtcLevelsRequest());
    dispatch(BalanceActions.getOtcBalanceRequest());
    dispatch(BankCardActions.getBankAccountListRequest());
  }, [])

  return (
    <Paper className={classes.paperStyle}>
      <Box px={isMobileScreen ? 2 : 4} py={2} borderBottom="#e9e9e9 1px solid">
        <CustomTypography variant="subtitle1"><FormattedMessage id="label.trader.level" /></CustomTypography>
      </Box>
      {didMount && bankAccountList.loading && otc.balance.otcBalance.loading && otc.universal.otcLevels.loading &&
        <LinearProgress />
      }
      {didMount && bankAccountList.loaded && otc.balance.otcBalance.loaded && otc.universal.otcLevels.loaded &&
        <Box px={isMobileScreen ? 2 : 4} display="flex">
          <Box my={2} mr={2}>
            {(isTrader && currentLevel > -1) ?
              <Avatar
                className={classes.levelIcon}
                style={{backgroundColor: '#ffffff'}}
              >
                {getLevelDisplayName(currentLevel)}
              </Avatar>
              :
              <img src={notTraderIcon} />
            }
          </Box>
          <Box display="flex" alignItems="center">
            {isTrader ?
              <Box display="flex" flexDirection="column" justifyContent="space-around" height="64px">
                <Box display="flex" alignItems="center">
                  {currentLevel >= lowestLevel &&
                    <Typography variant="subtitle2" color="secondary"><FormattedMessage id="label.trader.level.subtitle" values={{currentLevelDisplayName: getLevelDisplayName(currentLevel)}} /></Typography>
                  }
                </Box>
                {!isHighestLevel(currentLevel) &&
                  <Box>
                    <Box>
                      {currentDeposit >= getLevelUpScore(currentLevel) ?
                        <Typography variant="subtitle2" color="primary"><FormattedMessage id="label.trader.level.arrive" /></Typography>
                        :
                        <Typography variant="body2" color="secondary">
                          <FormattedMessage
                            id="description.trader.level"
                            values={{nextLevelDisplayName: getLevelDisplayName(nextLevel), levelUpRequirement: intl.formatNumber(getLevelUpRequirement(currentDeposit, getLevelUpScore(currentLevel)), {unitDisplay: 'long'})}}
                          />
                        </Typography>
                      }
                    </Box>
                    <Box mt={1} width="240px"><LevelLinearProgress variant="determinate" value={getLevelUpProgress(currentDeposit, getLevelUpScore(currentLevel))} /></Box>
                    {currentDeposit < getLevelUpScore(currentLevel) &&
                      <Box><Typography variant="body2" color="secondary">{`(${intl.formatNumber(currentDeposit, {unitDisplay: 'long'})}/${intl.formatNumber(getLevelUpScore(currentLevel), {unitDisplay: 'long'})})`}</Typography></Box>
                    }
                  </Box>
                }
              </Box>
              :
              <>
                <Box ml={2} display="inline-flex" flexDirection="column">
                  <Box fontWeight="bold"><Typography variant="subtitle2" color="secondary"><FormattedMessage id="label.trader.level.not.yet.arrive"/></Typography></Box>
                  {!isTrader &&
                    <Box><Button onClick={showLevelUpRequirementDialog} color="primary" style={{fontSize: '12px'}}><FormattedMessage id="action.level.up.requirement" /></Button></Box>
                  }
                </Box>
                {!isTrader && !isHighestLevel(currentLevel) &&
                  <Box ml={4} display="inline-flex" flexDirection="column" alignItems="center">
                    <Box><Button onClick={showLevelUpDialog} variant="contained" color="primary" style={{width: '190px'}}><FormattedMessage id="action.level.up.immediately" /></Button></Box>
                  </Box>
                }
              </>
            }
          </Box>
        </Box>
      }

      {didMount && bankAccountList.loaded && otc.balance.otcBalance.loaded && otc.universal.otcLevels.loaded && isTrader && !isHighestLevel(currentLevel) &&
        <Box px={4} pt={1} pb={4} borderTop="#e9e9e9 1px solid" textAlign={isMobileScreen ? 'center' : 'left'}>
          <Box><Button onClick={showLevelUpRequirementDialog} color="primary" style={{fontSize: '12px'}}><FormattedMessage id="action.level.up.requirement" /></Button></Box>
          <Box><Button onClick={showLevelUpDialog} variant="contained" color="primary" style={{width: '190px'}}><FormattedMessage id="action.level.up.immediately" /></Button></Box>
        </Box>
      }
    </Paper>
  )
}

export default injectIntl(TraderLevel);
