import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'GET_BANK_LIST',
  'GET_BANK_ACCOUNT_LIST',
  'ADD_BANK_ACCOUNT',
  'DELETE_BANK_ACCOUNT',
  'EDIT_BANK_ACCOUNT',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}