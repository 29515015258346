import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
 } from '@material-ui/core';

import { 
  makeStyles, 
  createStyles, 
  Theme,
  withStyles
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import BankCardActions from 'stores/otc/bankCard/actions';
import EnableBankCardDialog from './EnableBankCardDialog';


const SelectLabel = withStyles((theme: Theme) => ({
  outlined: {
    transform: 'translate(14px, 13px) scale(1)'
  }
}))(InputLabel);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    }
  }),
);

const BankCardInfoDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
    type,
    bankCardIndex,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const bankList = useSelector((state: any) => state.otc.bankCard.bankList);
  const bankAccountList = useSelector((state: any) => state.otc.bankCard.bankAccountList);
  const otcMeta = useSelector((state:any) => state.otc.universal.otcMeta);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    bankId: '',
    ownerName: '',
    bankAccount: '',
    dailyTransactionLimit: 0,
  });
  const validationField: any = ['bankId', 'ownerName', 'bankAccount', 'dailyTransactionLimit'];
  const [validations, setValidations] = React.useState<any>({
    bankId: [Vaildator.required,],
    ownerName: [Vaildator.required,],
    bankAccount: [Vaildator.required, Vaildator.isNumeric,],
    dailyTransactionLimit: [Vaildator.required, Vaildator.isNumeric,],
  });
  const currencyUnit = 'CNY';

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = {...validations};

    setFormData({ ...formData, [name]: value});

    if (validations[name]) {
      const validate  = Vaildator.onValidate({validations: validationsNew[name], name, value});
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage};

      setErrorMessage(newErrorMessage);
    }
  }

  const onSubmit = () => {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = {...validations};

    validationFieldList.forEach((key:string) => {
      if (validBol && validations[key]) {
        const validate  = Vaildator.onValidate({validations: validationsNew[key], name: key, value: formData[key]});
        let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      if (type === 'create') {
        const payload = {
          bankId: formData.bankId,
          name: formData.ownerName,
          currency: "CNY",
          account: formData.bankAccount,
          dailyQuota: formData.dailyTransactionLimit,
        };

        dispatch(BankCardActions.addBankAccountRequest(payload));
      } else {
        const payload = {
          bankAccountId: bankAccountList.data[bankCardIndex].id,
          dailyQuota: formData.dailyTransactionLimit,
        };

        dispatch(BankCardActions.editBankAccountRequest(payload));
      }
    }
  }

  useEffect(() => {
    if (otcMeta.loaded && otcMeta.data.accounts.defaultDailyLimit.cny) {
      const payload = {
        dailyTransactionLimit: otcMeta.data.accounts.defaultDailyLimit.cny,
      };

      setFormData({formData, ...payload})
    }
  }, [otcMeta.loaded])

  useEffect(() => {
    if (type === 'create' && bankAccountList.created) {
      dispatch(GlobalActions.toogleDialog({visible: false}));

      const bankAccount = {
        id: bankAccountList.addedBankAccountId,
        bankName: bankList.data.filter((item: any) => item.id === formData.bankId)[0].name,
        account: formData.bankAccount,
      };

      dispatch(GlobalActions.toogleDialog({
        visible: true,
        title: intl.formatMessage({id: 'dialog.title.enable.bank.card'}),
        component: <EnableBankCardDialog
                     {...props}
                     dialogConfig={dialogConfig}
                     bankAccount={bankAccount}
                   />,
        contentHasPadding: false,
      }));
    }
  }, [bankAccountList.created])

  useEffect(()=> {
    if (bankAccountList.loaded && type === 'edit') {
      const bankAccount = bankAccountList.data[bankCardIndex];
      const newFormData = {
        bankId: bankAccount.bank.id,
        ownerName: bankAccount.name,
        bankAccount: bankAccount.account,
        dailyTransactionLimit: bankAccount.dailyQuota,
      };

      setFormData({...formData, ...newFormData});
    }
  }, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box>
          <FormControl variant="outlined" fullWidth error={!!errorMessage['bankId']}>
            <SelectLabel disabled={type === 'edit'}><FormattedMessage id="label.bankName" /></SelectLabel>
            <Select
              disabled={type === 'edit'}
              name="bankId"
              value={formData.bankId}
              label={intl.formatMessage({id: 'placeholder.select.bank.card'})}
              margin="dense"
              onChange={onInputChange}
            >
              <MenuItem disabled value=""><em><FormattedMessage id="label.select.bank.card" /></em></MenuItem>
              {bankList.data.map((item: any) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
               ))
              }
            </Select>
            {
              errorMessage['bankId'] &&
              <FormHelperText><FormattedMessage id={errorMessage['bankId']} /></FormHelperText>
            }
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <TextField
              disabled={type === 'edit'}
              full-width
              name="ownerName"
              value={formData.ownerName}
              placeholder={intl.formatMessage({id: 'placeholder.owner.name'})}
              label={intl.formatMessage({id: 'label.owner.name'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              error={!!errorMessage['ownerName']}
              helperText={errorMessage['ownerName'] ? intl.formatMessage({id: `${errorMessage['ownerName']}`}) : ''}
            />
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <TextField
              disabled={type === 'edit'}
              full-width
              name="bankAccount"
              value={formData.bankAccount}
              placeholder={intl.formatMessage({id: 'placeholder.bank.account'})}
              label={intl.formatMessage({id: 'label.bank.card.account'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              error={!!errorMessage['bankAccount']}
              helperText={errorMessage['bankAccount'] ? intl.formatMessage({id: `${errorMessage['bankAccount']}`}) : ''}
            />
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <TextField
              full-width
              name="dailyTransactionLimit"
              value={formData.dailyTransactionLimit}
              placeholder={intl.formatMessage({id: 'placeholder.daily.transaction.limit'})}
              label={intl.formatMessage({id: 'label.daily.transaction.limit'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{currencyUnit}</InputAdornment>,
              }}
              error={!!errorMessage['dailyTransactionLimit']}
              helperText={errorMessage['dailyTransactionLimit'] ? intl.formatMessage({id: `${errorMessage['dailyTransactionLimit']}`}) : ''}
            />
          </FormControl>
        </Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent="flex-end" borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button className={classes.footerButton} onClick={onSubmit} variant="contained" color="primary">
            <FormattedMessage id={type === 'create' ? 'action.add.without.format' : 'action.modify.without.format'} />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(BankCardInfoDialog);