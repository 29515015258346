import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'GET_OTC_META',
  'GET_OTC_LEVELS',
  'SET_OTC_SELECTED_CRYPTOS',
  'SWITCH_OTC_PRESENCE',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}