import React, { useEffect } from 'react';
import { 
  Box,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { 
  makeStyles, 
  createStyles, 
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {FormattedMessage , injectIntl} from 'react-intl';
import GlobalActions from 'stores/global/actions';
import UniversalActions from 'stores/otc/universal/actions';


const IdleTimerDialog = (props:any) => {
  const {
    intl,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const paymentErrorAction = () => {
    const payload = {
      isActive: true,
    };

    dispatch(UniversalActions.switchOtcPresenceRequest(payload));
  } 

  return(
    <Box pl={3} pr={3} pb={3} pt={2} borderTop={1} borderColor='#e2e2e2'>
        <Box fontSize={16} paddingBottom={5}>
          {intl.formatMessage({id: 'otc.description.dashboard.idleContent'})}
        </Box>
        <Box textAlign={isMobileScreen ? 'center' : 'right'}>
            <Button color='primary' style={{ minWidth: 120, marginRight: 16, boxSizing: "border-box" }} onClick={paymentErrorAction}>{intl.formatMessage({id: 'otc.action.receiveOrder'})}</Button>
            <Button type='submit' color='primary' variant='contained' style={{ minWidth: 120, color: '#FFFFFF', backgroundColor: '#1976d2' }} onClick={()=> dispatch(GlobalActions.toogleDialog({visible: false}))}>
              {intl.formatMessage({id: 'otc.action.iSeeTheInfo'})}
            </Button>
        </Box>
    </Box>
  )
}

export default injectIntl(IdleTimerDialog);