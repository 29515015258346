import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Button,
  Avatar,
  useMediaQuery,
 } from '@material-ui/core';
 import DoneIcon from '@material-ui/icons/Done';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    levelIcon: {
      width: '64px',
      height: '64px',
      border: '1px solid #9b9b9b',
      borderRadius: '100px',
      fontSize: '2.8em',
      color: '#000000',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      footerButton: {
        minWidth: '100%',
      },
    },
  }),
);

const LevelUpSuccessDialog = (props: any) => {
  const {
    intl,
    CurrentLevel,
    CurrentLevelDisplayName,
    DialogConfig,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} display="flex" flexDirection="column" justifyContent="center" height="250px">
        <Box mb={2}>
          <Avatar
            className={classes.levelIcon}
            style={{backgroundColor: '#ffffff', margin: '0 auto'}}
          >
            {CurrentLevelDisplayName}
          </Avatar>
        </Box>
        <Box fontSize="20px" fontWeight="bold" textAlign="center"><FormattedMessage id="dialog.level.up.success.subtitle" values={{currentLevel: CurrentLevel}} /></Box>
      </Box>
      <Box px={isMobileScreen ? 2 : 3} py={2} display="flex" justifyContent={isMobileScreen?"center":"flex-end"} borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box ml={isMobileScreen ? 0 : 2} width={isMobileScreen?'100%':'auto'}>
          <Button onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.return.to.page" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(LevelUpSuccessDialog);
