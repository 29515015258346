import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'CHANGE_PASSWORD',
  'UPDATE_ME_PROFILE',
  'GET_USER_KYC',
  'UPDATE_USER_KYC',
  'CHANGE_USER_KYC_STATUS',
  'GET_BANK_ACCOUNT',
  'CREATE_BANK_ACCOUNT',
  'UPDATE_BANK_ACCOUNT',
  'UPLOAD_PRIVATE_IMAGE',
  'GET_ROLES',
  'CREATE_ROLE',
  'UPDATE_ROLE',
  'DELETE_ROLE',
  'GET_META_PERMISSIONS',
  'GET_ADMIN_LIST',
  'CREATE_ADMIN',
  'RESET_DEFAULT_PASSWORD',
  'SWITCH_ADMIN_ENABLE_STATUS',
  'UPDATE_ADMIN_ROLE',
  'GET_ROLE_LIST',
];

const  createActions:any = actionsHelper(names);

export const SHOW_MODIFY_ROLE_PAGE: string = 'SHOW_MODIFY_ROLE_PAGE';
const showModifyRolePage = (payload: any) => ({
  type: SHOW_MODIFY_ROLE_PAGE,
  payload,
});

export const CURRENT_MODIFY_ROLE: string = 'CURRENT_MODIFY_ROLE';
const currentModifyRole = (payload: any) => ({
  type: CURRENT_MODIFY_ROLE,
  payload,
});

export default {
  SHOW_MODIFY_ROLE_PAGE,
  showModifyRolePage,
  CURRENT_MODIFY_ROLE,
  currentModifyRole,
  ...createActions.types,
  ...createActions.actions
}
