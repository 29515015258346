export default [
  {
    path: '/user',
    component: 'layouts/user',
    isRoot: true,
    routes: [
      {
        title: '登入',
        path: '/user/login',
        component: 'user/Login',
      },
      {
        title: '註冊',
        path: '/user/signup',
        component: 'user/Signup',
      },
      {
        title: '忘記密碼',
        path: '/user/resetpassword',
        component: 'user/ResetPassword',
      },
      {
        title: 'E-mail驗證',
        path: '/user/emailverify',
        component: 'user/EmailVerify',
      },
      {
        title: '平台審核中',
        path: '/user/review',
        component: 'user/AccountReview',
      },
      {
        title: '帳號異常',
        path: '/user/exception',
        component: 'user/AccountException'
      }
    ],
  },
  {
    path: '/',
    component: 'layouts/drawerV2',
    redirect: {
      default: '/otc/dashboard',
      enterprise: '/otc/dashboard',
      investor: '/otc/dashboard',
    },
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'account.title',
        Icon: 'AccountCircleOutlinedIcon',
        path: '/account',
        authorize: ['default', 'investor', 'enterprise'],
        redirect: '/account/settings',
        breadcrumbLink: false,
        order: 999,
        routes: [
          {
            title: 'settings.title',
            path: '/account/settings',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'account/InvitationCode',
              },
              {
                component: 'account/ProfileInfo',
              },
              {
                component: 'otc/settings/TraderLevel',
              },
              {
                component: 'otc/settings/ContactAccount',
              },
            ],
            authorize: ['default', 'investor', 'enterprise'],
          },
          {
            title: 'authentication.title',
            path: '/account/authentication',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'otc/authentication/Index',
              },
            ],
            authorize: ['default'],
          },
          {
            title: 'bankcard.title',
            path: '/account/bankcard',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'otc/bankCard/Index',
              },
            ],
            authorize: ['default'],
          },
          {
            title: 'security.title',
            path: '/account/security',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'otc/securitySettings/Index',
              },
            ],
            authorize: ['default'],
          },
        ],
      },
      {
        title: 'title.otc',
        Icon: 'InsertChartOutlinedIcon',
        path: '/otc',
        authorize: ['default', 'investor', 'enterprise'],
        breadcrumbLink: false,
        order: 3,
        routes: [
          {
            title: 'title.otc.dashboard',
            path: '/otc/dashboard',
            component: 'otc/dashboard/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
          {
            title: 'title.otc.order',
            path: '/otc/order',
            component: 'otc/order/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
          {
            title: 'title.otc.wallet',
            path: '/otc/wallet',
            component: 'otc/wallet/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
        ],
      },
    ],
  },
];
