
function toCurrency(num: number) {
  var parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

const convertMillisecondsToSeconds = (millisecond: number) => millisecond / 1000;

const convertSecondsToMilliseconds = (seconds: number) => seconds * 1000;

export default { 
  toCurrency,
  convertMillisecondsToSeconds,
  convertSecondsToMilliseconds,
}