import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
 } from '@material-ui/core';
 import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { 
  makeStyles, 
  createStyles, 
  Theme,
  withStyles
} from '@material-ui/core/styles';
import BankCardActions from 'stores/otc/bankCard/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    message: {
      '& span': {
        display: 'flex'
      }
    },
  }),
);

const EnableBankCardDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
    bankAccount,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();


  const enableBankAccount = () => {
    const payload = {
      bankAccountId: bankAccount.id,
      isEnabled: true,
    };

    dispatch(BankCardActions.editBankAccountRequest(payload));
  }

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box className={classes.message}>
          <Box display="flex">
            <Box flexShrink={0}>{intl.formatMessage({id: 'dialog.description.enable.bank.card.1.1'})}　</Box>
            <Box flexShrink={0}>{`${bankAccount.bankName} (${intl.formatMessage({id: 'dialog.description.bank.card.last.digits'}, {lastDigits: 5})}${bankAccount.account.slice(-5)})`}</Box>
            <Box flexShrink={0}>　{intl.formatMessage({id: 'dialog.description.enable.bank.card.1.2'})}</Box>
          </Box>
          <FormattedMessage id="dialog.description.enable.bank.card.2" />
        </Box>
        <Box mt={2} mb={3} display="flex" alignItems="center" color="#f55221">
          <ErrorOutlineIcon fontSize="small" color="primary" />
          <Box ml={1} color="primary.main" fontWeight="500"><FormattedMessage id="dialog.notice.enable.bank.card" /></Box>
        </Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent="flex-end" borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.temporarily.close" />
        </Button>
        <Box ml={2}>
          <Button onClick={() => enableBankAccount()} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.start.enable" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(EnableBankCardDialog);