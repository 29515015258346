import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
 } from '@material-ui/core';
 import DoneIcon from '@material-ui/icons/Done';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import UserActions from 'stores/user/store/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    }
  }),
);

const LevelUpConfirmDialog = (props: any) => {
  const {
    intl,
    NextLevel,
    Deposit,
    TradeableAmount,
    DialogConfig,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const levelUp = (level: any) => {
    const payload = {
      level
    };

    dispatch(UserActions.changeTraderLevelRequest(payload));
  }

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={isMobileScreen?2:3} py={2} borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}} color="#2e2e2e">
        <Box mb={1} fontSize="16" fontWeight="bold"><FormattedMessage id="dialog.level.up.confirm.subtitle" values={{nextLevel: NextLevel}} /></Box>
        <Box><FormattedMessage id="dialog.level.up.confirm.description" values={{deposit: intl.formatNumber(Deposit, {unitDisplay: 'long'}), tradeableAmount: intl.formatNumber(TradeableAmount, {unitDisplay: 'long'})}} /></Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent={isMobileScreen ? "center" : "flex-end"} borderTop={DialogConfig.border.value ? 1 : 0} style={{ borderColor: 'rgba(0, 0, 0, .25)' }}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button onClick={() => levelUp(NextLevel)} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.level.up.confirm" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(LevelUpConfirmDialog);
