import CN from 'locales/basic/zh_CN.json';
const languages: any = {
  zh_CN: {
    locale: 'zh_CN',
    key: 'cn',
    label: '\u7C21\u9AD4\u4E2D\u6587',
    id: 0,
    messages: CN,
  },
};
export default languages;
