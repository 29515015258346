import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
 } from '@material-ui/core';
 import WarningIcon from '@material-ui/icons/Warning';

import { 
  makeStyles, 
  createStyles, 
  Theme,
  withStyles
} from '@material-ui/core/styles';
import BankCardActions from 'stores/otc/bankCard/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    message: {
      '& span': {
        display: 'flex'
      }
    },
  }),
);

const DeleteBankCardDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
    bankAccount,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteBankAccount = () => {
    dispatch(BankCardActions.deleteBankAccountRequest(bankAccount.id));
  }

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box className={classes.message}>
          <FormattedMessage
            id="dialog.description.delete.bank.card"
            values={{bankCard: <Box ml={1} fontWeight="bold">{`${bankAccount.name}（${bankAccount.account}）`}</Box>}}
          />
        </Box>
        <Box mt={2} mb={3} display="flex" alignItems="center" color="#f55221">
          <WarningIcon fontSize="small" htmlColor={'#ffba00'} />
          <Box ml={1} fontWeight="500"><FormattedMessage id="dialog.notice.delete.bank.card" /></Box>
        </Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent="flex-end" borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button onClick={deleteBankAccount} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.confirm.delete" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(DeleteBankCardDialog);