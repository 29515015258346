import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import {
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GlobalActions from 'stores/global/actions';

const Snackbar = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sharedData = useSelector((state: any) => state.global.sharedData);

  const getMessage = (eventName: string, orderType: string, eventData?: any) => {
    let result = {};

    // 新收款訂單
    if (eventName === 'NewOrder' && orderType === 'BUY') {
      result = {
        message: 'otc.notice.push.notification.buy.new.order',
        FilterOrderStatus: 'unprocessed',
        FilterOrderType: 'BUY',
      };
    }

    // 新付款訂單
    if (eventName === 'NewOrder' && orderType === 'SELL') {
      result = {
        message: 'otc.notice.push.notification.sell.new.order',
        FilterOrderStatus: 'unprocessed',
        FilterOrderType: 'SELL',
      };
    }

    // 訂單已付款
    if (eventName === 'BuyOrderPaid' && orderType === 'BUY') {
      result = {
        message: 'otc.notice.push.notification.buy.paid.order',
        FilterOrderStatus: 'finish',
        FilterOrderType: 'BUY',
      };
    }

    // 訂單已完成
    if (eventName === 'OrderComplete') {
      result = {
        message: 'otc.notice.push.notification.complete.order',
        FilterOrderStatus: 'finish',
        FilterOrderType: 'null',
      };
    }

    // 訂單已取消
    if (eventName === 'OrderCancelled') {
      result = {
        message: 'otc.notice.push.notification.cancelled.order',
        FilterOrderStatus: 'cancel',
        FilterOrderType: 'null',
      };
    }

    // 爭議訂單
    if (eventName === 'DisputingOrder') {
      result = {
        message: 'otc.notice.push.notification.disputing.order',
        FilterOrderStatus: 'unprocessed',
        FilterOrderType: 'null',
      };
    }

    if (eventName === 'OrderProcessedByAdmin') {
      if ((eventData?.actualCryptoAmount !== eventData?.cryptoAmount) || (eventData?.actualTotalAmount !== eventData?.totalAmount)) {
        // 更新訂單 訂單金額
        result = {
          message: 'otc.notice.push.notification.update.order.price',
          FilterOrderStatus: 'finish',
          FilterOrderType: 'null',
        };
      } else {
        // 更新訂單 訂單狀態
        result = {
          message: 'otc.notice.push.notification.update.order.status',
          FilterOrderStatus: 'finish',
          FilterOrderType: 'null',
        };
      }
    }

    return result;
  }

  const showSnackbar = (orderId: string, notificationData: any) => {
    dispatch(GlobalActions.snackbarRequest({
      visible: true,
      variant: 'dark',
      message: `${notificationData.message}`,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      actionComponent: <Link onClick={() => history.push(`/otc/order?filterOrderStatus=${notificationData.FilterOrderStatus}&filterOrderType=${notificationData.FilterOrderType}&orderId=${orderId}`)} style={{color: '#ffffff', cursor: 'pointer'}}>{orderId}</Link>,
      closeIcon: true,
    }));
  }

  // 新訂單
  useEffect(() => {
      if (sharedData.socket.otc && sharedData.socket.otc?.NewOrder) {
        const orderId = sharedData.socket.otc?.NewOrder.id;
        const orderType = sharedData.socket.otc?.NewOrder.type;

        showSnackbar(orderId, getMessage('NewOrder', orderType));
      }
  }, [sharedData.socket.otc?.NewOrder])

  // 訂單已付款
  useEffect(() => {
    if (sharedData.socket.otc && sharedData.socket.otc?.BuyOrderPaid) {
      const orderId = sharedData.socket.otc?.BuyOrderPaid.id;
      const orderType = sharedData.socket.otc?.BuyOrderPaid.type;

      showSnackbar(orderId, getMessage('BuyOrderPaid', orderType));
    }
  }, [sharedData.socket.otc?.BuyOrderPaid])

  // 訂單已完成
  useEffect(() => {
    if (sharedData.socket.otc && sharedData.socket.otc?.OrderComplete) {
      const orderId = sharedData.socket.otc?.OrderComplete.id;
      const orderType = sharedData.socket.otc?.OrderComplete.type;

      showSnackbar(orderId, getMessage('OrderComplete', orderType));
    }
  }, [sharedData.socket.otc?.OrderComplete])

  // 訂單已取消
  useEffect(() => {
    if (sharedData.socket.otc && sharedData.socket.otc?.OrderCancelled) {
      const orderId = sharedData.socket.otc?.OrderCancelled.id;
      const orderType = sharedData.socket.otc?.OrderCancelled.type;

      showSnackbar(orderId, getMessage('OrderCancelled', orderType));
    }
  }, [sharedData.socket.otc?.OrderCancelled])

  // 爭議訂單
  useEffect(() => {
    if (sharedData.socket.otc && sharedData.socket.otc?.DisputingOrder) {
      const orderId = sharedData.socket.otc?.DisputingOrder.id;
      const orderType = sharedData.socket.otc?.DisputingOrder.type;

      showSnackbar(orderId, getMessage('DisputingOrder', orderType));
    }
  }, [sharedData.socket.otc?.DisputingOrder])

  // 更新訂單
  useEffect(() => {
    if (sharedData.socket.otc && sharedData.socket.otc?.OrderProcessedByAdmin) {
      const orderId = sharedData.socket.otc?.OrderProcessedByAdmin.id;
      const orderType = sharedData.socket.otc?.OrderProcessedByAdmin.type;
      const eventData = JSON.parse(JSON.stringify(sharedData.socket.otc?.OrderProcessedByAdmin));

      delete eventData.id;
      delete eventData.type;
      showSnackbar(orderId, getMessage('OrderProcessedByAdmin', orderType, eventData));
    }
  }, [sharedData.socket.otc?.OrderProcessedByAdmin])

  return (
    <></>
  )
}
export default Snackbar;