import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
 } from '@material-ui/core';

import { 
  makeStyles,
  createStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
  }),
);

const ConfirmDeleteBankCardDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();


  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} mb={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box>
          <FormattedMessage id="dialog.description.confirm.delete.bank.card" />
        </Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent="flex-end" borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} className={classes.footerButton} variant="contained" color="primary">
          <FormattedMessage id="action.return" />
        </Button>
      </Box>
    </Box>
  )
}

export default injectIntl(ConfirmDeleteBankCardDialog);