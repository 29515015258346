import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  Paper,
  IconButton,
  LinearProgress,
 } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ContactAccountDialog from './components/ContactAccountDialog';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Config from 'config/basic/config.json';
import UserActions from 'stores/otc/user/actions';


const dialogConfig = Config.component.dialog;

const CustomTypography = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 500,
    },
  }),
)(Typography);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      }
    },
  }),
);

const ContactAccount = (props: any) => {
  const {
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const contactAccount = sharedData.user.data.contact;
  const skypeAccount = contactAccount?.skype;
  const telegramAccount = contactAccount?.telegram;
  const account = useSelector((state:any) => state.otc.user);
  const [didMount, setDidMount] = React.useState<any>(false);

  const showContactAccountDialog = (operatingType: string, accountType: string) => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.binding.contact.account'}, {accountType: accountType.slice(0, 1).toUpperCase() + accountType.slice(1)}),
      component: <ContactAccountDialog
                   {...props}
                   DialogConfig={dialogConfig}
                   accountType={accountType}
                   operatingType={operatingType}
                 />,
      contentHasPadding: false,
    }));
  }

  useEffect(()=> {
    setDidMount(true);
  }, [])

  return (
    <Paper className={classes.paperStyle}>
      <Box px={isMobileScreen ? 2 : 4} py={2} borderBottom="#e9e9e9 1px solid">
        <CustomTypography variant="subtitle1"><FormattedMessage id="label.setting.contact.account" /></CustomTypography>
        <Box mt={1}><Typography variant="body2" component="h6"><FormattedMessage id="description.setting.contact.account" /></Typography></Box>
      </Box>
      {didMount && sharedData.user.loading &&
        <LinearProgress />
      }
      {didMount && sharedData.user.loaded &&
        <Box p={3} px={isMobileScreen ? 2 : 4}>
          <Box display="flex" alignItems="center" justifyContent={isMobileScreen ? 'space-between' : 'flex-start'}>
            <Box mr={3} width="64px" textAlign="right"><Typography variant="body2" component="label"><FormattedMessage id="Skype" /></Typography></Box>
            {!!!skypeAccount ?
              <Button onClick={() => {showContactAccountDialog('add', 'skype')}} variant="contained" color="primary" disableElevation><FormattedMessage id="action.binding" /></Button>
              :
              <Box display='inline-flex' alignItems='center'>
                <Typography variant="body2" component="span">{skypeAccount}</Typography>
                <Box ml={1}><IconButton onClick={() => {showContactAccountDialog('edit', 'skype')}} size="small"><EditOutlinedIcon color="primary" /></IconButton></Box>
              </Box>
            }
          </Box>
          <Box display="flex" alignItems="center" mt={2} justifyContent={isMobileScreen ? 'space-between' : 'flex-start'}>
            <Box mr={3} width="64px" textAlign="right"><Typography variant="body2" component="label"><FormattedMessage id="Telegram" /></Typography></Box>
            {!!!telegramAccount ?
              <Button onClick={() => {showContactAccountDialog('add', 'telegram')}} variant="contained" color="primary" disableElevation><FormattedMessage id="action.binding" /></Button>
              :
              <Box display='inline-flex' alignItems='center'>
                <Typography variant="body2" component="span">{telegramAccount}</Typography>
                <Box ml={1}><IconButton onClick={() => {showContactAccountDialog('edit', 'telegram')}} size="small"><EditOutlinedIcon color="primary" /></IconButton></Box>
              </Box>
            }
          </Box>
        </Box>
      }
    </Paper>
  )
}

export default injectIntl(ContactAccount);
