import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import BasicConfig from 'config/basic/config.json';


const enableFeatureAcl: any = BasicConfig.basic.enableFeatureAcl.value;

const featureToggle = (id: string, configAclSet: any, apiAclSet: any): boolean => {
  if (enableFeatureAcl &&
      !_.isEmpty(configAclSet) && !_.isEmpty(id) &&
      !!_.intersection([id], Object.keys(configAclSet)).length) {
    const idAcl:any = configAclSet[id] || [];
    const apiAcl = Object.keys(apiAclSet).filter((apiAclKey: any) => apiAclSet[apiAclKey] === 'enabled');
    const isMatched: boolean = !!_.intersection(idAcl, apiAcl).length;

    return isMatched;
  } else {
    return true;
  }
}

const FeatureToggle = (props: any) => {
  const {
    id,
  } = props;

  const meta = useSelector((state:any) => state.global.meta);
  const configAclSet: any = useSelector((state: any) => state.global.featureAcl);
  const apiAclSet = meta.data.features || [];

  return (
    <>
      {featureToggle(id, configAclSet, apiAclSet) ?
        props.children
        :
        React.Fragment
      }
    </>
  )
}

export default FeatureToggle;
export {
  featureToggle,
}