import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  Link,
  useMediaQuery,
  TextField,
  Button,
} from '@material-ui/core';
import EditPasswordDialog from '../../pages/account/components/EditPassword';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import AccountActions from 'stores/account/store/actions';
import BasicConfig from 'config/basic/config.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sendBtn: {
      minWidth: '120px',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      marginLeft: theme.spacing(3),

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      },
    },
  }),
);

const dialogConfig = BasicConfig.component.dialog;
const InvitationCode = (props: any) => {
  const {
    intl
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [code, setCode] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const sharedData = useSelector((state: any) => state.global.sharedData);
  const userInfo = sharedData.user.data;

  useEffect(() => {
    setCode(userInfo.invitationCode);
  }, [userInfo.invitationCode])

  return (
    <>
      <Paper className={classes.paperStyle}>
        <Box px={isMobileScreen ? 2 : 4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="caption.account.invitation.code" /></Typography>
        </Box>
        <Box p={3} px={isMobileScreen ? 2 : 4}>
          {!userInfo.invitationCode && <Box pb={2}>
            <FormattedMessage id="description.account.unset.invitation.code" />
          </Box>}
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                disabled={!!userInfo.invitationCode}
                name="invitationCode"
                value={code}
                variant="outlined"
                size="small"
                placeholder={intl.formatMessage({ id: "placeholder.account.invitation.code"})}
                onChange={(evt: any) => {
                  setErrorMessage({ "invitationCode": "" });
                  setCode(evt.target.value);
                }}
                InputProps={{
                  style: {
                    backgroundColor: (!!userInfo.invitationCode?'#efefef':'#ffffff'),
                  },
                }}
                error={!!errorMessage['invitationCode']}
                helperText={errorMessage['invitationCode'] ? intl.formatMessage({ id: `${errorMessage['invitationCode']}` }) : ''}
              />
              {!userInfo.invitationCode && <Button className={classes.sendBtn} variant="contained" disableElevation
                onClick={() => {
                  if (!code) {
                    setErrorMessage({ "invitationCode": "error.validator.required" });
                    return;
                  }
                  dispatch(AccountActions.updateMeProfileRequest({ invitationCode: code }));
                }}
              >
                <FormattedMessage id="action.confirm" />
              </Button>}
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default injectIntl(InvitationCode);
