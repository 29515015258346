import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  Divider,
  CircularProgress,
 } from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import General from 'utils/general';
import Jssha from 'jssha';
import Cookies from 'js-cookie';
import Format from 'utils/format';
import UserActions from 'stores/otc/user/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    captchaField: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, .12)',
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        color: '#f44336',
      }
    },
  }),
);

const ResetWalletPasswordDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const account = useSelector((state:any) => state.otc.user);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    walletPassword: '',
    confirmPassword: '',
    captcha: '',
  });
  const passwordMaxLength = 6;
  const validationField: any = ['walletPassword', 'confirmPassword', 'captcha'];
  const [validations, setValidations] = React.useState<any>({
    walletPassword: [Vaildator.required, Vaildator.isNumeric, Vaildator.lengthLimit(6)],
    confirmPassword: [Vaildator.required],
    captcha: [Vaildator.required],
  });
  const [isAllFieldFilled, setIsAllFieldFilled] = React.useState<any>(General.checkAllFieldsAreFilled({...formData}));
  const [countdownTimer, SetCountdownTimer] = React.useState<any>(0);
  const sharedData = useSelector((state: any) => state.global.sharedData);
  const userInfo = sharedData.user.data;
  const countdownTimerDuration = 60;
  const [startCountdown, setStartCountdown] = React.useState<any>(false);


  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = {...validations};

    setIsAllFieldFilled(General.checkAllFieldsAreFilled({...formData, [name]: value}));
    setFormData({ ...formData, [name]: value});

    if (validations[name]) {
      if(name === 'confirmPassword') {
        let confirmationPW = Vaildator.confirmationPW(formData.walletPassword);
        validationsNew = {...validations, [name]: [Vaildator.required, confirmationPW]};
        setValidations({...validationsNew});
      }
      const validate  = Vaildator.onValidate({validations: validationsNew[name], name, value});
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage};

      setErrorMessage(newErrorMessage);
    }
  }

  const onSubmit = () => {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = {...validations};

    validationFieldList.forEach((key:string) => {
      if (validBol && validations[key]) {
        if(key === 'confirmPassword') {
          let confirmationPW = Vaildator.confirmationPW(formData.walletPassword);
          validationsNew = {...validations, [key]: [Vaildator.required, confirmationPW]};
          setValidations({...validationsNew});
        }

        const validate  = Vaildator.onValidate({validations: validationsNew[key], name: key, value: formData[key]});
        let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      const walletPasswordHash = getHashText(formData.walletPassword);

      dispatch(UserActions.changeWalletPasswordRequest({fundPassword: walletPasswordHash, pincode: formData.captcha}));
    }
  }

  const getHashText = (originData: string) => {
    const jssha = new Jssha('SHA3-512', 'TEXT');

    jssha.update(originData);

    return jssha.getHash('HEX');
  }

  const resendCaptcha = () => {
    const email = userInfo.email;
    const countdownTimerStartTime = Date.now();

    Cookies.set(email, JSON.stringify({countdownTimerStartTime}), {expires: 1});
    General.initCountdownTimer(SetCountdownTimer, countdownTimerDuration);
  }

  const send = () => {
    setStartCountdown(true);
    dispatch(UserActions.createWalletPasswordPinCodeRequest(userInfo.email));
  }

  const initCountdownTimerCookie = (duration: number) => {
    const email = userInfo.email;
    const currentAccountCookie = Cookies.get(email);

    if (currentAccountCookie !== undefined) {
      const endTime = JSON.parse(currentAccountCookie).countdownTimerStartTime + Format.convertSecondsToMilliseconds(duration);
      const currentTime = Date.now();
      const remainingTime = Math.floor(Format.convertMillisecondsToSeconds(endTime - currentTime));

      if (remainingTime > 1) {
        General.initCountdownTimer(SetCountdownTimer, remainingTime);
      }
    }
  }

  useEffect(() => {
    if (startCountdown && account.createWalletPasswordPinCode.loaded) {
      resendCaptcha();
      setStartCountdown(false);
    }
  }, [account.createWalletPasswordPinCode.loaded])

  useEffect(() => {
    if(!account.changeWalletPassword.loading && account.changeWalletPassword.loaded && formData.walletPassword) {
      dispatch(GlobalActions.snackbarRequest({
        visible: true,
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        message: {
          id: 'snackbar.success.reset.wallet.password',
          values: {hours: '24'},
        },
      }));
      dispatch(GlobalActions.toogleDialog({visible:false}))
    }
  }, [account.changeWalletPassword.loaded])

  useEffect(()=> {
    initCountdownTimerCookie(countdownTimerDuration);
  }, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box>
          <FormControl fullWidth>
            <TextField
              full-width
              type="password"
              name="walletPassword"
              value={formData.walletPassword}
              placeholder={intl.formatMessage({id: 'placeholder.password.max.length.1'}, {maxLength: passwordMaxLength})}
              label={intl.formatMessage({id: 'label.new.password'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              error={!!errorMessage['walletPassword']}
              helperText={errorMessage['walletPassword'] ? intl.formatMessage({id: `${errorMessage['walletPassword']}`}, {limitLength: passwordMaxLength}) : ''}
            />
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <TextField
              full-width
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              placeholder={intl.formatMessage({id: 'placeholder.confirm.password'})}
              label={intl.formatMessage({id: 'label.confirm.password'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              error={!!errorMessage['confirmPassword']}
              helperText={errorMessage['confirmPassword'] ? intl.formatMessage({id: `${errorMessage['confirmPassword']}`}) : ''}
            />
          </FormControl>
        </Box>
      </Box>
      <Box px={3} py={2} borderTop={1} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body2" component="h3"><FormattedMessage id="dialog.description.reset.wallet.password" />：</Typography>
            <Typography variant="body2" component="h3">{userInfo.email}</Typography>
          </Box>
          <Button onClick={send} disabled={account.createWalletPasswordPinCode.loading || countdownTimer > 0} variant="contained" color="primary" style={{minWidth: '120px'}}>
            {countdownTimer > 0 ?
              <FormattedMessage id="action.resend.captcha" values={{countdownTimer}} />
              :
              <>
                {account.createWalletPasswordPinCode.loading &&
                  <CircularProgress size={24} />
                }
                <FormattedMessage id="action.send.captcha" />
              </>
            }
          </Button>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <TextField
              full-width
              name="captcha"
              value={formData.captcha}
              placeholder={intl.formatMessage({id: 'placeholder.captcha'})}
              variant="outlined"
              margin="dense"
              onChange={onInputChange}
              error={!!errorMessage['captcha']}
              helperText={errorMessage['captcha'] ? intl.formatMessage({id: `${errorMessage['captcha']}`}) : ''}
            />
          </FormControl>
        </Box>
        <Box mt={3} style={{color: '#f55221'}}>
          <FormattedMessage
            id="description.caveat.change.wallet.password"
            values={{hours: '24'}}
          />
        </Box>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent={isMobileScreen?"center":"flex-end"}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button disabled={account.changeWalletPassword.loading || !isAllFieldFilled} className={classes.footerButton} onClick={onSubmit} variant="contained" color="primary">
            {account.changeWalletPassword.loading &&
              <CircularProgress size={24} />
            }
            <FormattedMessage id="action.submit.without.format" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(ResetWalletPasswordDialog);
