import React, {useEffect} from 'react';
import {
  Typography,
  Box,
  Paper,
  Tooltip,
  Link,
  useMediaQuery,
 } from '@material-ui/core';
import WalletAddressDialog from 'pages/account/components/WalletAddress';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import LanguageMenu from 'components/LanguageMenu';
import GlobalActions from 'stores/global/actions';
import AccountActions from 'stores/account/store/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      },
    },
  }),
);

const ProfileInfo = (props:any) => {
  const {
    intl,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const userInfo = sharedData.user.data;

  return (
    <>
      <Paper className={classes.paperStyle}>
        <Box px={isMobileScreen ? 2 : 4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="caption.account.profile"/></Typography>
        </Box>
        <Box p={3} px={isMobileScreen ? 2 : 4}>
          <Box display="flex" pb={2} justifyContent={isMobileScreen ? "space-between" : "flex-start"}>
            <Typography variant="body2"><FormattedMessage id="label.account.info"/></Typography>
            <Box pl={1}>{userInfo.email || ''}</Box>
          </Box>
          <Box display="flex" pb={2} alignItems="center" justifyContent={isMobileScreen ? "space-between" : "flex-start"}>
            <Typography variant="body2"><FormattedMessage id="label.language"/></Typography>

            <Box ml={1}  display="flex" alignItems="center">
              <LanguageMenu onChange={(locale:any) => {
                dispatch(AccountActions.updateMeProfileRequest({language: locale}))
              }} ml={1} border="solid 1px rgba(0, 0, 0, 0.15)" borderRadius={4} minWidth={200}/>
            </Box>
          </Box>
          {
            userInfo.current == 'investor' &&

            <Box display="flex" pb={2} alignItems="center" data-testid="walletAddressBlock" justifyContent={isMobileScreen ? "space-between" : "flex-start"}>
            <Typography variant="body2"><FormattedMessage id="label.wallet.address"/></Typography>
            <Box  ml={1}>
              <Tooltip placement="top" title={userInfo.note || ''}><Typography variant="body2">: {userInfo.note && userInfo.note.substring(0,5) + '...' + userInfo.note.substring(userInfo.note.length - 5,userInfo.note.length)|| ''}</Typography></Tooltip>
            </Box>
            <Box ml={1}>
              <Link data-testid="editWalletBtn" href='' onClick={(e:any) => {
                e.preventDefault();
                dispatch(GlobalActions.toogleDialog({
                  visible: true,
                  title: userInfo.note ? 'label.modify.wallet.address' : 'label.add.wallet.address',
                  component: <WalletAddressDialog {...props} walletAddress={userInfo.note}/>
                }))
              }}><FormattedMessage id={userInfo.note ? 'action.modify' : 'action.add'}/></Link>
            </Box>
          </Box>
        }
        </Box>
      </Paper>
    </>
  )
}

export default injectIntl(ProfileInfo);
