import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import GlobalActions from 'stores/global/actions';


const ForceModifyDefaultPassword = (props:any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const account = useSelector((state:any) => state.account.store);
  const user = useSelector((state: any) => state.global.sharedData.user);

  useEffect(() => {
    const isDefaultPassword = user.data.isDefaultPassword;

    if(account.changePassword.loaded && isDefaultPassword) {
      dispatch(GlobalActions.getUserInfoRequest());
    }
  }, [account.changePassword.loaded])

  useEffect(() => {
    if (user.loaded) {
      const isDefaultPassword = user.data.isDefaultPassword;

      if (isDefaultPassword) {
        history.push('/account/settings');
      }
    }
  }, [user.loaded, location.pathname])

  useEffect(() => {
    dispatch(GlobalActions.getUserInfoRequest());
  }, [])

  return (
    <></>
  )
}

export default ForceModifyDefaultPassword;