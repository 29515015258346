import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'CREATE_WALLET_PASSWORD',
  'CHANGE_WALLET_PASSWORD',
  'CREATE_WALLET_PASSWORD_PIN_CODE',
  'GET_KYC_TOKEN',
  'GET_KYC_STATUS',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}