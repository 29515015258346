import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Paper,
  Button,
  Link,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import bankCardLogo from '../assets/images/bank_card.svg';
import DeleteBankCardDialog from './components/DeleteBankCardDialog';
import BankCardInfoDialog from './components/BankCardInfoDialog';
import { 
  makeStyles, 
  createStyles, 
  Theme,
  withStyles
} from '@material-ui/core/styles';
import Config from 'config/basic/config.json';
import BankCardActions from 'stores/otc/bankCard/actions';
import UserActions from 'stores/otc/user/actions';
import UniversalActions from 'stores/otc/universal/actions';
import FeatureToggle, {featureToggle} from 'components/ACL/FeatureToggle';

const dialogConfig = Config.component.dialog;
const CustomTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      color: theme.palette.secondary.main,
    },
  }),
)((props: any) => <TableCell {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bankCard: {
      color: '#ffffff',
      borderRadius: '4px',
      backgroundImage: `url(${bankCardLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      maxWidth: '220px',
    },
    tradingStatus: {
      margin: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '1em',
        fontWeight: 400,
        marginRight: '8px',
      }
    },
  }),
);


const Index = (props: any) => {
  const {
    intl,
    history,
  } = props;
  const dispatch = useDispatch();
  const tableTitleList = [
    {
      id: 'bankCard',
      text: intl.formatMessage({ id: 'label.bank.card' }),
      minWidth: 200,
    },
    {
      id: 'bankName',
      text: intl.formatMessage({ id: 'label.bank.name' }),
      minWidth: 90,
    },
    {
      id: 'ownerName',
      text: intl.formatMessage({ id: 'label.owner.name' }),
      minWidth: 80,
    },
    {
      id: 'bankAccount',
      text: intl.formatMessage({ id: 'label.bank.account' }),
      minWidth: 180,
    },
    {
      id: 'tradingStatus',
      text: intl.formatMessage({ id: 'label.trading.status' }),
      minWidth: 80,
    },
    {
      id: 'dailyTransactionLimit',
      text: intl.formatMessage({ id: 'label.daily.transaction.limit' }),
      minWidth: 180,
    },
    {
      id: 'operating',
      text: intl.formatMessage({ id: 'label.action' }),
      minWidth: 50,
    },
  ];
  const tableColumnCount = tableTitleList.length;
  const bankCardMaxLimit = 10;
  const meta = useSelector((state:any) => state.global.meta);
  const configAclSet: any = useSelector((state: any) => state.global.featureAcl);
  const apiAclSet = meta.data.features;
  const bankAccount = useSelector((state: any) => state.otc.bankCard);
  const bankAccountList = useSelector((state: any) => state.otc.bankCard.bankAccountList);
  const bankAccountCount = bankAccountList.data.length;
  const account = useSelector((state:any) => state.otc.user);
  const otcMeta = useSelector((state:any) => state.otc.universal.otcMeta);
  const KYCStatus = account.kycStatus?.data;
  const isKYC = KYCStatus === 'PASS';
  const [didMount, setDidMount] = React.useState<any>(false);
  const classes = useStyles();
  const currencyUnit = 'CNY';
  const fractionDigits = 2;


  const addBankCard = () => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.create.bank.card'}),
      component: <BankCardInfoDialog
                   {...props}
                   dialogConfig={dialogConfig}
                   type="create"
                 />,
      contentHasPadding: false,
    }));
  }

  const editBankCard = (bankCardIndex: number) => {
    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.edit.bank.card'}),
      component: <BankCardInfoDialog
                   {...props}
                   dialogConfig={dialogConfig}
                   type="edit"
                   bankCardIndex={bankCardIndex}
                 />,
      contentHasPadding: false,
    }));
  }

  const deleteBankCard = ({id, name, account}: any) => {
    const bankAccount = {
      id,
      name,
      account,
    };

    dispatch(GlobalActions.toogleDialog({
      visible: true,
      title: intl.formatMessage({id: 'dialog.title.delete.bank.card'}),
      component: <DeleteBankCardDialog
                   {...props}
                   dialogConfig={dialogConfig}
                   bankAccount={bankAccount}
                 />,
      contentHasPadding: false,
    }));
  }

  const switchBankCard = (bankAccount: any) => {
    const payload = {
      bankAccountId: bankAccount.id,
      isEnabled: !bankAccount.isEnabled,
    };

    dispatch(BankCardActions.editBankAccountRequest(payload));
  }

  const formatBankAccount = (bankAccount: string) => {
    const bankAccountLength = bankAccount.length;
    const primaryPart = bankAccount.slice(0, 4);
    const secondaryPart = bankAccount.slice(4, bankAccountLength);
    const formatSecondaryPart = secondaryPart.replace(/(.{5})/g, '$1 ').trim();

    return `${primaryPart} ${formatSecondaryPart}`;
  }

  useEffect(()=> {
    setDidMount(true);

    if (featureToggle('kyc', configAclSet, apiAclSet)) {
      dispatch(UserActions.getKycStatusRequest());
    }

    dispatch(UniversalActions.getOtcMetaRequest());
    dispatch(BankCardActions.getBankListRequest());
    dispatch(BankCardActions.getBankAccountListRequest());
  },[])

  return (
    <Box>
      <Paper>
        <Box px={4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="label.binding.bank.card" /></Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box display="inline-flex" alignItems="center">
              <NotificationsNoneOutlinedIcon htmlColor="#ffba00" />
              <Box ml={1}><FormattedMessage id="description.bank.card.maximum.limit" values={{bankCardMaxLimit}} /></Box>
            </Box>
            <Box>
              <Button onClick={addBankCard} disabled={(featureToggle('kyc', configAclSet, apiAclSet) && !isKYC) || bankAccountCount >= bankCardMaxLimit} color="primary" startIcon={<AddIcon />}>
                <FormattedMessage id="action.add.bank.card" />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box px={4} pb={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableTitleList.map((item: any) => 
                    (
                      <TableCell key={item.id} style={{minWidth: `${item.minWidth}px`}}>{item.text}</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {bankAccountList.loading && otcMeta.loading &&
                  <TableRow>
                    <TableCell padding="none" colSpan={tableColumnCount} color="primary"><LinearProgress /></TableCell>
                  </TableRow>
                }

                {didMount && (featureToggle('kyc', configAclSet, apiAclSet) ? account.kycStatus?.loaded && isKYC : true) && otcMeta.loaded && bankAccountList.loaded && bankAccountList.data.map((item: any, index: number) => {
                   return (
                     <TableRow hover>
                       <CustomTableCell>
                         <Box p={1} className={classes.bankCard}>
                           <Box fontWeight="500">{item.bank.name}</Box>
                           <Box fontWeight="500" fontSize="12px">{item.name}</Box>
                           <Box mt={1} fontSize="12px" textAlign="right">{formatBankAccount(item.account)}</Box>
                         </Box>
                       </CustomTableCell>
                       <CustomTableCell>{item.bank.name}</CustomTableCell>
                       <CustomTableCell>{item.name}</CustomTableCell>
                       <CustomTableCell>{formatBankAccount(item.account)}</CustomTableCell>
                       <CustomTableCell>{item.isEnabled}
                         <FormControlLabel
                           className={classes.tradingStatus}
                           value="start"
                           control={<Switch checked={item.isEnabled} onChange={() => switchBankCard(item)} color="primary" size="small" />}
                           label={item.isEnabled ? intl.formatMessage({ id: 'action.enable' }) : intl.formatMessage({ id: 'action.close.without.format' })}
                           labelPlacement="start"
                         />
                       </CustomTableCell>
                       <CustomTableCell>
                         <Box display="flex" alignItems="center">
                           <Box style={{textDecoration: 'underline'}}>{intl.formatNumber(item.dailyQuota, {style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits})}</Box>
                           <Box mx={1} color="#a2a2a2">{currencyUnit}</Box>
                           <Box><IconButton onClick={() => editBankCard(index)} size="small"><EditOutlinedIcon color="primary" /></IconButton></Box>
                         </Box>
                       </CustomTableCell>
                       <CustomTableCell><IconButton onClick={() => deleteBankCard(item)} size="small"><DeleteForeverOutlinedIcon htmlColor={'#f55221'} /></IconButton></CustomTableCell>
                     </TableRow>
                   );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          {didMount && (featureToggle('kyc', configAclSet, apiAclSet) ? account.kycStatus?.loaded && isKYC : true) && !bankAccountList.loading && bankAccountCount === 0 &&
            <Box display="flex" justifyContent="center">
              <Box mt={9} mb={6} display="inline-flex" flexDirection="column" alignItems="center">
                <Box><FormattedMessage id="description.not.yet.binding.bank.card" /></Box>
                <Box mt={3}><Button onClick={addBankCard} variant="contained" color="primary" style={{minWidth: '256px'}}><FormattedMessage id="action.add.bank.card" /></Button></Box>
              </Box>
            </Box>
          }
          {didMount && (featureToggle('kyc', configAclSet, apiAclSet) && account.kycStatus?.loaded && !isKYC) && !bankAccountList.loading &&
            <Box display="flex" justifyContent="center">
              <Box mt={9} mb={6} display="inline-flex" flexDirection="column" alignItems="center">
                <Box><FormattedMessage id="description.not.yet.verified.identity" /></Box>
                <Box mt={3}><Button onClick={() => history.push('/account/authentication')} variant="contained" color="primary" style={{minWidth: '256px'}}><FormattedMessage id="action.verify" /></Button></Box>
              </Box>
            </Box>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default injectIntl(Index);