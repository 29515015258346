import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

var moduleName = 'otc';
const genSocketHost = () => {
  return `${window.Config.NODE_ENV === 'local' ? `ws.otcmama-beta.leadproject.net` : `${window.Config[moduleName == 'global' ? 'SOCKET_HOST' : `${String(moduleName).toUpperCase().replace(/-/g, '_')}_SOCKET_HOST`]}`}`
}

const genApiHost = () => {
  return `${window.Config.NODE_ENV === 'local' ? `/${moduleName}-api` : `${window.Config[moduleName == 'global' ? 'API_HOST' : `${String(moduleName).toUpperCase().replace(/-/g, '_')}_API_HOST`]}`}`
}

const Socket = (props: any) => {
  const dispatch = useDispatch();
  const [didMount, setDidMount] = React.useState<any>(false);
  const global = useSelector((state:any) => state.global);
  const sharedData = useSelector((state: any) => state.global.sharedData.user);
  const traderId = sharedData.data.id;
  let [initWebSocket, setInitWebSocket] = React.useState<any>(false);
  let [echo, setEcho] = React.useState<any>(null);


  const connectionStatus = (status: any) => {
    switch (status) {
      case 'connected':
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'ConnectStatus',
          data: true
        }))
        break;
      case 'unavailable':
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'ConnectStatus',
          data: false
        }))
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (echo !== null && typeof echo.disconnect === 'function' && global.deleteUserAccessToken.loaded) {
      echo.disconnect();
      setEcho(null);
      setInitWebSocket(false);
    }
  }, [global.deleteUserAccessToken.loaded])

  const initEventList = () => {
    echo = new Echo({
      wsHost: genSocketHost(),
      wsPort: 6001,
      key: 'WEB-SERVER-KEY',
      broadcaster: 'pusher',
      cluster: 'app',
      forceTLS: true,
      authEndpoint: `${genApiHost()}/v1/broadcasting/auth`,
      auth: {
        headers: {
          Accept: 'application/json',
        },
      },
    });
    setEcho(echo);
    setInitWebSocket(true);

    connectionStatus(echo.connector.pusher.connection.state)
    echo.connector.pusher.connection.bind('state_change', function (states: any) {
      if (states.previous != states.current) {
        connectionStatus(states.current)
      }
    });

    if (traderId) {
      let json: any = localStorage.getItem('account-auth');
      const accountAuth: any = JSON.parse(json) || {};
      echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + accountAuth.token
    }

    if (echo.connector.options.auth.headers['Authorization']) {
      // private
      echo.private('trader').listen('.ExchangeRate', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'ExchangeRate',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.NewOrder', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'NewOrder',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.BuyOrderPaid', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: 'otc',
          event: 'BuyOrderPaid',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.OrderComplete', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'OrderComplete',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.OrderCancelled', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'OrderCancelled',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.DisputingOrder', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'DisputingOrder',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.OrderProcessedByAdmin', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'OrderProcessedByAdmin',
          data: message
        }))
      });
      echo.private(`trader-${traderId}`).listen('.SellOrderCallingBack', (message: any) => {
        dispatch(GlobalActions.setSocketData({
          module: moduleName,
          event: 'SellOrderCallingBack',
          data: message
        }))
      });
    }
    else {
      // public
    }
  }

  useEffect(() => {
    if (didMount && sharedData.loaded && sharedData.data.id && echo === null && !initWebSocket) {
      initEventList();
    }
  }, [didMount, sharedData.loaded])

  useEffect(() => {
    setDidMount(true);
  }, [])

  return (
    <></>
  )

}
export default Socket;