import request from 'utils/request.ts';
const axios:any = new request('otc').request;


export async function createWalletPassword(payload: any): Promise<any> {
  return axios.post('/v1/me:set-fund-password', payload);
}

export async function changeWalletPassword(payload: any): Promise<any> {
  return axios.post('/v1/me:change-fund-password', payload);
}

export async function createWalletPasswordPinCode(): Promise<any> {
  return axios.post('/v1/me:notify-fund-password-reset');
}

export async function getKYCToken(): Promise<any> {
  return axios.post('/v1/me:create-kyc-token');
}

export async function getKycStatus():Promise<any> {
  return axios.get(`/v1/me`);
}