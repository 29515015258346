import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import snsWebSdk from '@sumsub/websdk';
import clsx from 'clsx';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Paper,
  Button,
  Chip,
  CircularProgress,
  Link,
  LinearProgress,
  useMediaQuery,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import basicConfig from 'config/basic/config.json';
import UserActions from 'stores/otc/user/actions';
import UniversalActions from 'stores/otc/universal/actions';
import {
  getKYCToken,
} from 'services/otc/userApi';
import otcConfig from 'config/otc/config.json';
import onboarding_step_1 from 'pages/otc/assets/images/onboarding_step_1.svg';

const otcConfigWithType: any = otcConfig;
const dialogConfig = basicConfig.component.dialog;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    isKYC: {
      backgroundColor: '#19d28b',
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      },
    },
  }),
);


const Index = (props: any) => {
  const {
    intl,
    history,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const locale = useSelector((state:any) => state.global.locale);
  const account = useSelector((state:any) => state.otc.user);
  const otc = useSelector((state:any) => state.otc);
  const email = sharedData.user.data.email;
  const KYCToken = account.accountKYC.data.token;
  const KYCStatus = account.kycStatus.data;
  const isKYC = KYCStatus === 'PASS';
  const lowestLevel = otc.universal.otcLevels.data[0]?.level;
  const [isVerifying, setIsVerifying] = React.useState<any>(false);
  const [webSDKisReady, setWebSDKisReady] = React.useState<any>(false);
  const [KYCStatusFromSDK, setKYCStatusFromSDK] = React.useState<any>(undefined);
  const [didMount, setDidMount] = React.useState<any>(false);


  const contactCustomerService = (email: string) => {
    window.open(`mailto:${email}`);
  }

  const getSupportedLanguage = (locale: string) => {
    const supportedLanguage = locale.split('_')[1].toLowerCase();
    let result = '';

    switch (supportedLanguage) {
      case 'cn':
        result = 'zh';
        break;
      case 'tw':
        result = 'zh-tw';
        break;
      default:
        result = supportedLanguage;
        break;
    }

    return result;
  }

  const initKYCSDK = () => {
    let apiUrl         = window.Config.NODE_ENV === 'prod' ? otcConfigWithType.static.sumsubWebSDK.apiUrl.production : otcConfigWithType.static.sumsubWebSDK.apiUrl.test;
    let flowName       = window.Config.NODE_ENV === 'prod' ? otcConfigWithType.account.sumsubWebSDK.value.flowName.production : otcConfigWithType.account.sumsubWebSDK.value.flowName.test;
    let applicantPhone = '';
    let accessToken    = KYCToken;

    let snsWebSdkInstance = snsWebSdk.Builder(apiUrl, flowName)
      .withAccessToken(
        accessToken,
        (newAccessTokenCallback: any) => {
          getKYCToken().then((data: any) => {
            newAccessTokenCallback(data.kycToken);
          });
        }
      )
      .withConf({
        lang: getSupportedLanguage(locale),
        email: email,
        phone: applicantPhone,
        onMessage: (type: any, payload: any) => {
          switch (type) {
            case 'idCheck.onReady':
              setWebSDKisReady(true);
              break;
            case 'idCheck.applicantStatus':
              if (payload.reviewStatus === 'completed') {
                if (payload.reviewResult.reviewAnswer === 'GREEN') {
                  dispatch(GlobalActions.snackbarRequest({
                    visible: true,
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    message: {
                      id: 'snackbar.success.authentication',
                    },
                    actionComponent: <Link onClick={() => history.push('/otc/wallet')} underline="always" style={{color: '#ffffff', cursor: 'pointer'}}><FormattedMessage id="action.recharge" /></Link>,
                    autoHideDuration: null,
                  }));
                  setKYCStatusFromSDK('success');
                } else {
                  dispatch(GlobalActions.snackbarRequest({
                    visible: true,
                    variant: 'info',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    message: {
                      id: 'snackbar.error.can.not.authentication',
                    },
                    actionComponent: <Link onClick={() => contactCustomerService(intl.formatMessage({id: 'service.email'}))} underline="always" style={{color: '#ffffff', cursor: 'pointer'}}><FormattedMessage id="action.contact.customer.service" /></Link>,
                    autoHideDuration: null,
                  }));
                  setKYCStatusFromSDK('fail');
                }
              } else {
                setKYCStatusFromSDK(payload.reviewStatus);
              }
              break;
            default:
              break;
          }
        },
        onError: (error: any) => {
          console.error('WebSDK onError', error)
        },
      })
      .build();

    snsWebSdkInstance.launch('#websdk-container')
  }

  const startVerify = () => {
    setIsVerifying(true);
    dispatch(UserActions.getKycTokenRequest());
  }

  useEffect(() => {
    if (isVerifying && KYCToken && KYCStatus === 'NEW') {
      initKYCSDK();
    }
  }, [KYCToken])

  useEffect(() => {
    if (didMount && account.kycStatus.loaded && (KYCStatus === 'FAIL' || KYCStatus === 'SUSPECTED')) {
      dispatch(GlobalActions.snackbarRequest({
        visible: true,
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        message: {
          id: 'snackbar.error.can.not.authentication',
        },
        actionComponent: <Link onClick={() => contactCustomerService(intl.formatMessage({id: 'service.email'}))} underline="always" style={{color: '#ffffff', cursor: 'pointer'}}><FormattedMessage id="action.contact.customer.service" /></Link>,
        autoHideDuration: null,
      }));
    }
  }, [account.kycStatus.loaded])

  useEffect(()=> {
    setDidMount(true);
    dispatch(UserActions.getKycStatusRequest());
    dispatch(UniversalActions.getOtcLevelsRequest());
  },[])

  return (
    <>
      {KYCStatus === 'NEW' &&
        <Box display={!isVerifying ? 'none' : 'block'}>
          <Paper className={classes.paperStyle}>
            <Box pb={5}>
              <div id="websdk-container"></div>
            </Box>
            <Box p={2} pb={4} display="flex" justifyContent="center">
              {(webSDKisReady && KYCStatusFromSDK === 'success') &&
                <Button onClick={() => history.push('/otc/wallet')} variant="contained" color="primary" style={{width: '256px'}}><FormattedMessage id="action.next" />：<FormattedMessage id="action.recharge" /></Button>
              }
              {(webSDKisReady && KYCStatusFromSDK === 'fail') &&
                <Button onClick={() => contactCustomerService(intl.formatMessage({id: 'service.email'}))} variant="contained" color="primary" style={{width: '256px'}}><FormattedMessage id="action.contact.customer.service" /></Button>
              }
            </Box>
          </Paper>
        </Box>
      }
      {!isVerifying &&
        <>
          <Box>
            <Paper className={classes.paperStyle}>
              <Box px={isMobileScreen?2:4} py={2} borderBottom="#e9e9e9 1px solid">
                <Typography variant="subtitle1" component="h3"><FormattedMessage id="label.authentication" /></Typography>
              </Box>
              {account.kycStatus.loading && otc.universal.otcLevels.loading &&
                <LinearProgress />
              }
              {account.kycStatus.loaded && otc.universal.otcLevels.loaded &&
                <Box px={isMobileScreen?2:4} pb={2} mt={2} display="flex" alignItems="center">
                  <AccountCircleOutlinedIcon />
                  <Box ml={1} mr={2}><FormattedMessage id="label.authentication" /></Box>
                  <Chip label={intl.formatMessage({id: `status.authentication.${KYCStatus.length > 0 ? KYCStatus.toLowerCase() : 'NEW'.toLowerCase()}`})} className={clsx({[classes.isKYC]: isKYC})} />
                </Box>
              }
            </Paper>
          </Box>
          {account.kycStatus.loaded && otc.universal.otcLevels.loaded && KYCStatus === 'NEW' &&
            <Box mt={2}>
              <Paper className={classes.paperStyle}>
                <Box p={8} display="flex" flexDirection="column" flexWrap="nowrap" alignItems="center" justifyContent="center">
                  <img src={onboarding_step_1} />
                  <Box mt={2} display="flex" flexDirection="column" flexWrap="nowrap" alignItems="center" justifyContent="center" fontWeight="500" color="secondary.main">
                    <FormattedMessage id="description.not.yet.be.trader.1" />
                    <FormattedMessage id="description.not.yet.be.trader.2" values={{lowestLevel}} />
                  </Box>
                  <Box mt={2} mb={4} fontWeight="500" color="secondary.main"><FormattedMessage id="description.not.yet.be.trader.3" /></Box>
                  <Button
                    disabled={account.accountKYC.loading}
                    onClick={startVerify}
                    startIcon={account.accountKYC.loading && <CircularProgress color="primary" size="20px" />}
                    variant="contained"
                    color="primary"
                    style={{width: '256px'}}
                  >
                    {intl.formatMessage({id: 'action.verify'})}
                  </Button>
                </Box>
              </Paper>
            </Box>
          }
        </>
      }
    </>
  )
}

export default injectIntl(Index);
