import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  Divider,
  CircularProgress,
 } from '@material-ui/core';
 import DoneIcon from '@material-ui/icons/Done';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import Jssha from 'jssha';
import UserActions from 'stores/otc/user/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    }
  }),
);

const SettingPasswordDialog = (props: any) => {
  const {
    intl,
    dialogConfig,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const account = useSelector((state:any) => state.otc.user);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    loginPassword: '',
    walletPassword: '',
    confirmPassword: '',
  });
  const passwordMaxLength = 6;
  const validationField: any = ['loginPassword', 'walletPassword', 'confirmPassword'];
  const [validations, setValidations] = React.useState<any>({
    loginPassword: [Vaildator.required, Vaildator.passwordRegex],
    walletPassword: [Vaildator.required, Vaildator.isNumeric, Vaildator.lengthLimit(6)],
    confirmPassword: [Vaildator.required],
  });

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let validationsNew = {...validations};

    setFormData({ ...formData, [name]: value});

    if (validations[name]) {
      if(name === 'confirmPassword') {
        let confirmationPW = Vaildator.confirmationPW(formData.walletPassword);
        validationsNew = {...validations, [name]: [Vaildator.required, confirmationPW]};
        setValidations({...validationsNew});
      }
      const validate  = Vaildator.onValidate({validations: validationsNew[name], name, value});
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage};

      setErrorMessage(newErrorMessage);
    }
  }

  const onSubmit = () => {
    let validBol: any = true;
    let validationFieldList = [...validationField];
    let validationsNew = {...validations};

    validationFieldList.forEach((key:string) => {
      if (validBol && validations[key]) {
        if(key === 'confirmPassword') {
          let confirmationPW = Vaildator.confirmationPW(formData.walletPassword);
          validationsNew = {...validations, [key]: [Vaildator.required, confirmationPW]};
          setValidations({...validationsNew});
        }

        const validate  = Vaildator.onValidate({validations: validationsNew[key], name: key, value: formData[key]});
        let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
      }
    });

    if (validBol) {
      const loginPasswordHash = getHashText(formData.loginPassword);
      const walletPasswordHash = getHashText(formData.walletPassword);
      const payload = {
        password: loginPasswordHash,
        fundPassword: walletPasswordHash,
      };

      dispatch(UserActions.createWalletPasswordRequest(payload));
    }
  }

  const getHashText = (originData: string) => {
    const jssha = new Jssha('SHA3-512', 'TEXT');

    jssha.update(originData);

    return jssha.getHash('HEX');
  }

  useEffect(() => {
    if(!account.createWalletPassword.loading && account.createWalletPassword.loaded && formData.walletPassword) {
      dispatch(GlobalActions.getUserInfoRequest());
      dispatch(GlobalActions.snackbarRequest({
        visible: true,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        message: {
          id: 'snackbar.success.setting.wallet.password',
        },
      }));
      dispatch(GlobalActions.toogleDialog({visible:false}))
    }

  }, [account.createWalletPassword.loaded])

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Box px={isMobileScreen?2:3} py={2}>
          <Box mb={2}><Typography variant="subtitle2" color="primary"><FormattedMessage id="description.change.wallet.password.1" /></Typography></Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                full-width
                type="password"
                name="loginPassword"
                value={formData.loginPassword}
                placeholder={intl.formatMessage({id: 'placeholder.please.input.something'}, {something: intl.formatMessage({id: 'label.login.password'})})}
                label={intl.formatMessage({id: 'label.login.password'})}
                variant="outlined"
                margin="dense"
                onChange={onInputChange}
                error={!!errorMessage['loginPassword']}
                helperText={errorMessage['loginPassword'] ? intl.formatMessage({id: `${errorMessage['loginPassword']}`}) : ''}
              />
            </FormControl>
          </Box>
        </Box>
        <Divider style={{backgroundColor: 'rgba(0, 0, 0, .25)'}} />
        <Box px={isMobileScreen?2:3} py={2}>
          <Box mb={2}><Typography variant="subtitle2" color="primary"><FormattedMessage id="description.change.wallet.password.2" values={{passwordLength: passwordMaxLength}} /></Typography></Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                full-width
                type="password"
                name="walletPassword"
                value={formData.walletPassword}
                placeholder={intl.formatMessage({id: 'placeholder.password.max.length'}, {maxLength: passwordMaxLength})}
                label={intl.formatMessage({id: 'label.wallet.password'})}
                variant="outlined"
                margin="dense"
                onChange={onInputChange}
                error={!!errorMessage['walletPassword']}
                helperText={errorMessage['walletPassword'] ? intl.formatMessage({id: `${errorMessage['walletPassword']}`}, {limitLength: passwordMaxLength}) : ''}
              />
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth>
              <TextField
                full-width
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                placeholder={intl.formatMessage({id: 'placeholder.password.max.length'}, {maxLength: passwordMaxLength})}
                label={intl.formatMessage({id: 'label.confirm.wallet.password'})}
                variant="outlined"
                margin="dense"
                onChange={onInputChange}
                error={!!errorMessage['confirmPassword']}
                helperText={errorMessage['confirmPassword'] ? intl.formatMessage({id: `${errorMessage['confirmPassword']}`}) : ''}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box px={isMobileScreen?2:3} py={2} display="flex" justifyContent={isMobileScreen?"center":"flex-end"} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button disabled={account.createWalletPassword.loading} className={classes.footerButton} onClick={onSubmit} variant="contained" color="primary">
            {account.createWalletPassword.loading &&
              <CircularProgress size={24} />
            }
            <FormattedMessage id="action.submit.without.format" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(SettingPasswordDialog);
