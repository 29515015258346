import React, {useEffect} from 'react';
import {
  Typography,
  Box,
  Paper,
  Link,
  useMediaQuery
 } from '@material-ui/core';
import EditPasswordDialog from '../../pages/account/components/EditPassword';
import {useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import GlobalActions from 'stores/global/actions';
import BasicConfig from 'config/basic/config.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down('sm')]: {
      paperStyle: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      },
    },
  }),
);

const dialogConfig = BasicConfig.component.dialog;
const Security = (props:any) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Paper className={classes.paperStyle}>
        <Box px={isMobileScreen ? 2 : 4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="caption.account.edit.password"/></Typography>
        </Box>
        <Box p={3} px={isMobileScreen ? 2 : 4}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2"><FormattedMessage id="label.password"/></Typography>
            <Box ml={1} display="flex" alignItems="center">
              <Typography component="p">: **********</Typography>
              <Link data-testid="editPasswordBtn" href="" onClick={(e:any) => {
                e.preventDefault()
                dispatch(GlobalActions.toogleDialog({
                  visible: true,
                  title: 'dialog.change.password.title',
                  contentHasPadding: false,
                  component: <EditPasswordDialog {...props} dialogConfig={dialogConfig} />
                }))
              }} style={{marginLeft: 8}}><FormattedMessage id="action.modify"/></Link>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default Security;
