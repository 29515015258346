import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { 
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
 } from '@material-ui/core';
 import DoneIcon from '@material-ui/icons/Done';

import { 
  makeStyles, 
  createStyles, 
  Theme,
  withStyles
} from '@material-ui/core/styles';
import Vaildator from 'utils/validator';
import AccountActions from 'stores/account/store/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    }
  }),
);

const ContactAccountDialog = (props: any) => {
  const {
    intl,
    accountType,
    DialogConfig,
    operatingType,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const global = useSelector((state:any) => state.global);
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const user = sharedData.user;
  const contactAccount = sharedData.user.data.contact;
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    skype: '',
    telegram: '',
  });
  const accountTypeUppercase = accountType.slice(0, 1).toUpperCase() + accountType.slice(1);

  const validationField: any = [accountType];

  const validations: any = {
    skype: [Vaildator.required],
    telegram: [Vaildator.required],
  };

  function onInputChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    let newFormData: any = {...formData};

    newFormData[name] = value;

    if (validations[name]) {
      const validate  = Vaildator.onValidate({validations: validations[name], name, value});
      const newErrorMessage: any = { ...errorMessage, ...validate.errorMessage}

      setErrorMessage(newErrorMessage);
    }

    setFormData(newFormData)
  }

  const onSubmit = () => {
    let validBol: any = true;
    let validationFieldList = [...validationField];

    validationFieldList.forEach((key:string) => {
      if (validBol && validations[key]) {
        const validate  = Vaildator.onValidate({validations: validations[key], name: key, value: formData[key]});
        let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol;
        console.log(validBol);
      }
    });
    
    if (validBol) {
      const payload = {
        contact: {
          [accountType]: formData[accountType],
        },
        file: {},
      };

      dispatch(AccountActions.updateMeProfileRequest(payload));
    }
  }

  useEffect(()=> {
    if (operatingType === 'edit') {
      setFormData(contactAccount);
    }
  }, [])

  return (
    <Box>
      <Box px={3} py={2} borderTop={DialogConfig.border.value ? 1 : 0} borderBottom={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Typography variant="body2" component="h6" color="secondary">
          <FormattedMessage id="dialog.binding.contact.description" values={{breakingLine: <br />, accountType: accountTypeUppercase}} />
        </Typography>
      </Box>
      <Box px={3} py={2}>
        <FormControl fullWidth>
          <TextField
            full-width
            name={accountType}
            value={formData[accountType]}
            placeholder={intl.formatMessage({id: 'binding.contact.account'}, {accountType: accountTypeUppercase})}
            label={`* ${intl.formatMessage({id: 'label.contact.account'}, {accountType: accountTypeUppercase})}`}
            variant="outlined"
            margin="dense"
            onChange={onInputChange}
            error={!!errorMessage[accountType]}
            helperText={errorMessage[accountType] ? intl.formatMessage({id: `${errorMessage[accountType]}`}) : ''}
          />
        </FormControl>
      </Box>
      <Box px={3} py={2} display="flex" justifyContent="flex-end" borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        <Button className={classes.footerButton} onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} color="primary">
          <FormattedMessage id="action.cancel.without.format" />
        </Button>
        <Box ml={2}>
          <Button className={classes.footerButton} onClick={onSubmit} variant="contained" color="primary">
            <FormattedMessage id="action.complete" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(ContactAccountDialog);