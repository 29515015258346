import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import { useIdleTimer } from 'react-idle-timer';
import IdleTimerDialog from 'pages/otc/components/IdleTimerDialog';
import UniversalActions from 'stores/otc/universal/actions';

const IdleTimer = (props: any) => {
    const dispatch = useDispatch();
    const global = useSelector((state: any) => state.global);
    const sharedData = useSelector((state:any) => state.global.sharedData);
    const user = sharedData.user;
    const otc = useSelector((state: any) => state.otc);
    const [didMount, setDidMount] = React.useState<any>(false);
    const idleTimMinute = 5;

    const handleOnIdle = () => {
      const accountAuth: any = localStorage.getItem('account-auth');
      const authToken = accountAuth ? JSON.parse(accountAuth).token : undefined;

      if (authToken) {
        if (user.data.isActive) {
          const payload = {
            isActive: false,
          };

          dispatch(UniversalActions.switchOtcPresenceRequest(payload));
          dispatch(GlobalActions.toogleDialog({
            visible: true,
            title: 'title.otc.idleTitle',
            close: false,
            contentHasPadding: false,
            component: <IdleTimerDialog />
          }));
        }
      }
    }

    const covertMinuteToSecond = (minute: number) => {
      const oneMinuteSeconds = 60 * 1000;

      return minute * oneMinuteSeconds;
    }

    useIdleTimer({
      timeout: covertMinuteToSecond(idleTimMinute),
      onIdle: handleOnIdle,
      debounce: 500,
    });

    useEffect(() => {
      if (didMount &&
          otc.universal.otcSwitchPresence.loaded && otc.universal.otcSwitchPresence.data &&
          global.globalDialog.visible) {
        dispatch(GlobalActions.toogleDialog({visible: false}));
      }
    }, [didMount, otc.universal.otcSwitchPresence.loaded])

    useEffect(() => {
      setDidMount(true);
    }, [])

    return (
      <></>
    )

}
export default IdleTimer;