import React, { useEffect } from 'react';
import {FormattedMessage, injectIntl, FormattedNumber} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import GlobalActions from 'stores/global/actions';
import {
  Box,
  Typography,
  Button,
  Avatar,
  useMediaQuery,
 } from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
} from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButton: {
      minWidth: '120px',
    },
    row: {
      '& > div:not(:first-child)': {
        marginTop: '24px',
      }
    },
    levelIcon: {
      width: '32px',
      height: '32px',
      border: '1px solid #9b9b9b',
      borderRadius: '100px',
      fontSize: '1.3em',
      color: '#000000',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      footerButton: {
        minWidth: '100%',
      },
    },
  }),
);

const LevelUpDialog = (props: any) => {
  const {
    intl,
    Deposit,
    TradeableAmount,
    GetLevelDisplayName,
    DialogConfig,
    Levels,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=> {}, [])

  return (
    <Box>
      <Box px={3} py={2} className={classes.row} borderTop={DialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
        {
          Levels.map((item: any) => {
            const currentLevelDisplayName = GetLevelDisplayName(item.level);

            return (
              <>
                <Box display="flex" alignItems="center">
                  <Avatar
                    className={classes.levelIcon}
                    style={{backgroundColor: '#ffffff'}}
                  >
                    {currentLevelDisplayName}
                  </Avatar>
                  <Box ml={2}>
                    <FormattedMessage
                      id="dialog.level.up.requirement"
                      values={{currentLevelDisplayName, deposit: intl.formatNumber(item.totalAmount, {unitDisplay: 'long'}), tradeableAmount: intl.formatNumber(item.chargeAmount, {unitDisplay: 'long'})}}
                    />
                  </Box>
                </Box>
              </>
            );
          })
        }
      </Box>
      <Box px={isMobileScreen ? 2 : 3} py={2} display="flex" justifyContent="flex-end" borderTop={DialogConfig.border.value ? 1 : 0} style={{ borderColor: 'rgba(0, 0, 0, .25)' }}>
        <Box ml={isMobileScreen ? 0 : 2} width={isMobileScreen?'100%':'auto'}>
          <Button onClick={() => dispatch(GlobalActions.toogleDialog({visible: false}))} className={classes.footerButton} variant="contained" color="primary">
            <FormattedMessage id="action.i.know" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default injectIntl(LevelUpDialog);
