import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'GET_OTC_BALANCE',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}