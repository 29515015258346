import _ from 'lodash';
import Format from 'utils/format';


const checkAllFieldsAreFilled = (formData: any) => !_.values(formData).map((item: any) => item !== null && item !== '').includes(false);

const initCountdownTimer = (setTimerState: any, duration: number) => {
  const decreaseValue = 1000;

  duration = duration * 1000;
  setTimerState(Format.convertMillisecondsToSeconds(duration));

  const timerId = setInterval(() => {
    duration = duration - decreaseValue;

    if (duration > -decreaseValue) {
      setTimerState(Format.convertMillisecondsToSeconds(duration));
    } else {
      clearInterval(timerId);
    }
  }, decreaseValue);
}

export default {
  checkAllFieldsAreFilled,
  initCountdownTimer,
}